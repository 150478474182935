<template>
  <div class="filters-container">
    <component
      v-if="displayComponent"
      :is="displayComponent"
      v-bind="$attrs"
      v-on="$listeners"
      :facets="facets"
      :none-value="noneValue"
      :source-types="sourceTypes"
      :form-data="formData"
      :total-count="totalCount"
      :show-clear="!!appliedFiltersCount"
    ></component>
  </div>
</template>

<script>
import {TAB_ID_FULL_TEXT, TAB_ID_INDEXED_RECORDS, TAB_ID_SOURCES} from '@common/utils/consts.search';

import FiltersFullText from './FiltersFullText';
import FiltersIndexed from './FiltersIndexed';
import FiltersSources from './FiltersSources';

export default {
  inheritAttrs: false,
  props: {
    activeTab: String,
    facets: Object,
    noneValue: String,
    sourceTypes: Array,
    formData: Object,
    totalCount: Number,
    appliedFiltersCount: Number,
  },
  computed: {
    componentMapping() {
      return {
        [TAB_ID_INDEXED_RECORDS]: FiltersIndexed,
        [TAB_ID_FULL_TEXT]: FiltersFullText,
        [TAB_ID_SOURCES]: FiltersSources,
      };
    },
    displayComponent() {
      return this.componentMapping[this.activeTab];
    },
  },
  components: {FiltersFullText, FiltersSources, FiltersIndexed},
};
</script>

<style lang="scss" scoped>
.filters-container {
  &::v-deep .heading {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $divider-line-color;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }
}
</style>
