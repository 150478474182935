<template>
  <div class="filters-full-text">
    <div class="heading">
      <div class="bold">Filters</div>
      <div class="clear-all" v-if="showClear"><a @click="clearAllFilters">Clear All</a></div>
    </div>
    <div v-if="noFilters" class="supplemental">No filters to apply.</div>
    <filter-item
      label="All Categories"
      :options="categoriesOptions"
      :cut-count="filterCutCount"
      :hide-header-count="true"
      @select="selectTypeCategory"
      @deselect="deSelectTypeCategory"
    ></filter-item>

    <filter-item
      label="All Publication Locations"
      :options="countryOptions"
      :cut-count="filterCutCount"
      @select="selectType($event.object_id, 'country')"
      @deselect="deSelectType('country')"
    ></filter-item>

    <filter-item
      label="All Ancestral Places"
      :options="ancestralPlacesOptions"
      :cut-count="filterCutCount"
      :force-cut="true"
      @select="selectTypeAncestralPlace"
      @deselect="deSelectTypeAncestralPlace"
    ></filter-item>

    <filter-item
      label="All Chinese Surnames"
      :options="clansOptions"
      :cut-count="filterCutCount"
      :force-cut="true"
      @select="selectType($event.name_hant || $event.name_ch || $event.object_id, 'clan_name')"
      @deselect="deSelectType('clan_name')"
    ></filter-item>
  </div>
</template>

<script>
import {isChineseText} from '@common/utils/utils';

import FilterItem from './FilterItem';
import commonFiltersComputed from './commonFiltersComputed';
import commonFiltersMethods from './commonFiltersMethods';

export default {
  props: {
    facets: Object,
    noneValue: String,
    sourceTypes: Array,
    formData: Object,
    totalCount: Number,
    showClear: Boolean,
  },
  data() {
    return {
      filterCutCount: 5,
      sourceTypeTypeName: 'source_type',
    };
  },
  computed: {
    ...commonFiltersComputed,
    countDisplay() {
      return this.totalCount >= 10000 ? '10000+' : this.totalCount;
    },
    countryOptions() {
      if (!this.facets.country) {
        return [];
      }
      let options = this.facets.country;
      const formCountry = this.formData.country;
      if (formCountry && !options.find(item => item.object_id === formCountry)) {
        options = [{name: formCountry, object_id: formCountry, count: 0}, ...options];
      }
      return this.normalizeOptions(options, 'country');
    },
    clansOptions() {
      if (!this.facets.clans) {
        return [];
      }
      let options = this.facets.clans.map(clan => {
        const nameCh =
          clan.name_hant && clan.name_ch !== clan.name_hant ? `${clan.name_ch} / ${clan.name_hant}` : clan.name_ch;
        const name = [clan.pinyin || '', nameCh].join(' ').trim() || clan.name;
        return {...clan, name};
      });
      const formClanName = this.formData.clan_name;
      if (formClanName && !options.find(item => this.isTypeItemClanNameSelected(item, formClanName))) {
        const nameDisplay = isChineseText(formClanName) ? formClanName : `${formClanName} (All Translations)`;
        options = [{name_ch: formClanName, name: nameDisplay, count: this.countDisplay, ignoreCount: true}, ...options];
      }
      return this.normalizeOptions(options, 'clan_name', 'name_ch', 'count', 'desc');
    },
    noneName() {
      return 'None';
    },
    noFilters() {
      return (
        !this.countryOptions.length &&
        !this.categoriesOptions.length &&
        !this.ancestralPlacesOptions.length &&
        !this.clansOptions.length
      );
    },
  },
  methods: {
    ...commonFiltersMethods,
    isTypeItemSelected(item, typeName) {
      const mapping = {
        clan_name: this.isTypeItemClanNameSelected,
        ancestral_place: this.isTypeItemAncestralPlaceSelected,
        category_id: this.isTypeItemByIdSelected,
      };
      const method = mapping[typeName];
      const selectedValue = this.formData[typeName];
      return method ? method(item, selectedValue) : item.object_id === selectedValue;
    },
    isTypeItemClanNameSelected(item, selectedValue) {
      if (!selectedValue) {
        return false;
      }
      return ['name_ch', 'name_hant', 'object_id'].some(field => item[field] === selectedValue);
    },
    isTypeItemByIdSelected(item, selectedValue) {
      return selectedValue ? selectedValue === item.id : false;
    },
    isTypeItemAncestralPlaceSelected(item, selectedValue) {
      return selectedValue ? item.object_id == selectedValue.id : false;
    },
    selectTypeAncestralPlace(value) {
      this.selectType(
        {address_en: value.name, full_address_en: value.name, id: value.object_id},
        value.selectField,
        value.deselectFields
      );
    },
    deSelectTypeAncestralPlace(value) {
      if (value.selectField) {
        this.deSelectType(value.selectField);
      } else {
        this.selectType(null, null, ['ancestral_level_3', 'ancestral_level_2', 'ancestral_level_1']);
      }
    },
    clearAllFilters() {
      this.selectType(null, null, [
        'category_id',
        'source_type',
        'country',
        'clan_name',
        'ancestral_level_1',
        'ancestral_level_2',
        'ancestral_level_3',
      ]);
    },
  },
  components: {FilterItem},
};
</script>

<style lang="scss" scoped></style>
