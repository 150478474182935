import consts from '@/base/utils/consts';

export function getExtensionByFilename(filename) {
  return filename && filename.includes('.') ? filename.split('.').pop().toLowerCase() : '';
}

export function isImageFile(filename) {
  const extension = getExtensionByFilename(filename);
  return consts.imageExtensions.includes(extension);
}

export function isVideoFile(filename) {
  const extension = getExtensionByFilename(filename);
  return consts.videoExtensions.includes(extension);
}

export function isAudioFile(filename) {
  const extension = getExtensionByFilename(filename);
  return consts.audioExtensions.includes(extension);
}

export function getPreviewSrcByFileExtension(vm, extension) {
  const srcByExtension = {
    pdf: vm.$getAsset('/assets/filetypes/file-pdf.png'),
    doc: vm.$getAsset('/assets/filetypes/file-word.png'),
    docx: vm.$getAsset('/assets/filetypes/file-word.png'),
    xlsx: vm.$getAsset('/assets/filetypes/file-excel.png'),
    xls: vm.$getAsset('/assets/filetypes/file-excel.png'),
  };
  return srcByExtension[extension] || vm.$getAsset('/assets/filetypes/file-other.png');
}

export function getNonImagePreviewSrcByFilename(vm, filename) {
  if (isImageFile(filename) || isVideoFile(filename) || isAudioFile(filename)) {
    return null;
  }
  return getPreviewSrcByFileExtension(vm, getExtensionByFilename(filename));
}
