<template>
  <multiselect
    v-bind="$attrs"
    :value="value"
    :placeholder="placeholder"
    :preserve-search="false"
    :internal-search="false"
    :clear-on-select="true"
    :hide-selected="false"
    :show-no-options="false"
    :show-no-results="false"
    selectLabel=""
    selectedLabel=""
    deselectLabel=""
    :option-height="optionHeight"
    :max-height="maxHeight"
    ref="multiselect"
    @open="onOpen"
    @select="onSelect"
    @search-change="$emit('search-change', $event)"
    @close="onClose"
  >
    <template v-slot:clear>
      <div
        class="caret-icon close-icon"
        v-if="hasValue && (alwaysShowValue || !isMultiselectOpened())"
        @mousedown.prevent.stop="clearPlace()"
      >
        <close-icon></close-icon>
      </div>
    </template>
    <template v-slot:caret>
      <div v-if="hasValue && (alwaysShowValue || !isMultiselectOpened())" class="caret-icon"></div>
    </template>
    <template v-slot:beforeList>
      <div class="helper-before-list" v-if="!loading && !searchQuery && isMultiselectOpened()">
        Start typing to search for places...
      </div>
      <div class="helper-before-list" v-if="showPlaceNotFound()">Place not found</div>
    </template>
    <template v-slot:afterList>
      <div class="loading-after-list" v-if="loading"><span>Searching...</span></div>
    </template>
    <template v-slot:singleLabel="props">
      <span>
        {{ customLabel(props.option, false) || placeholder }}
      </span>
    </template>
    <template v-slot:option="params">
      <signature-text-icon v-if="params.option.isAddCustom" :size="22"></signature-text-icon>
      <map-marker-icon v-else :size="22"></map-marker-icon>
      <span class="option-label text-sm">
        <span>{{ customLabel(params.option, true) }}</span>
      </span>
      <span class="level-name text-sm">{{ params.option.level_name }}</span>
    </template>
  </multiselect>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import CloseIcon from 'vue-material-design-icons/Close';
import MapMarkerIcon from 'vue-material-design-icons/MapMarkerOutline';
import SignatureTextIcon from 'vue-material-design-icons/SignatureText';
import Multiselect from 'vue-multiselect';

export default {
  inheritAttrs: false,
  props: {
    value: Object,
    loading: Boolean,
    searchQuery: String,
    placeholder: String,
    alwaysShowValue: {type: Boolean, default: false},
  },
  data() {
    const optionHeight = 56;
    return {
      optionHeight: optionHeight,
    };
  },
  computed: {
    hasValue() {
      return this.value && this.value[this.$attrs.label];
    },
    visibleItemsCount() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.tablet ? 3 : 5;
    },
    maxHeight() {
      return this.optionHeight * this.visibleItemsCount;
    },
  },
  methods: {
    autofocus() {
      this.$refs.multiselect.$el.focus();
    },
    isMultiselectOpened() {
      return this.$refs.multiselect && this.$refs.multiselect.isOpen;
    },
    customLabel(option, forOptionList) {
      if (isEmpty(option)) return '';
      if (option.isAddCustom && forOptionList) return option.customOptionLabel;
      return option[this.$attrs.label];
    },
    clearPlace() {
      if (this.alwaysShowValue) {
        this.$refs.multiselect.deactivate();
      }
      this.$emit('clear');
    },
    showPlaceNotFound() {
      const notFound = !this.loading && this.searchQuery && !this.$attrs.options.length && this.isMultiselectOpened();
      return this.hasValue ? notFound && this.searchQuery !== this.value[this.$attrs.label] : notFound;
    },
    onOpen(event) {
      if (this.alwaysShowValue && this.value && this.value[this.$attrs.label]) {
        this.$refs.multiselect.updateSearch(this.value[this.$attrs.label]);
        this.$nextTick(() => {
          this.$refs.multiselect.$refs.search.select();
        });
      }
      this.$emit('open', event);
    },
    onClose(event) {
      if (this.searchQuery && this.$attrs.options.length) {
        const index = this.$refs.multiselect.pointer || 0;
        this.$emit('select', this.$attrs.options[index]);
      }
      this.$emit('close', event);
    },
    onSelect(event) {
      if (this.searchQuery) {
        return; // ignore this event, instead select is emited on close;
      }
      this.$emit('select', event);
    },
  },
  name: 'PlaceMultiselect',
  components: {Multiselect, CloseIcon, MapMarkerIcon, SignatureTextIcon},
};
</script>

<style scoped lang="scss">
.multiselect::v-deep {
  .multiselect__spinner {
    right: 30px;
  }
  .multiselect__input {
    padding-right: 24px;
  }

  .material-design-icon__svg {
    color: #666;
  }

  .multiselect__option {
    padding: 8px 12px;
    height: 56px;
    display: flex;
    align-items: center;

    .material-design-icon__svg {
      flex-grow: 0;
      flex-shrink: 0;
      color: $neutral-500;
    }

    .option-label {
      flex-grow: 1;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 0 20px 0 8px;
      font-weight: 500;
    }

    .level-name {
      flex-shrink: 0;
      max-width: 60px;
      color: $neutral-500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.multiselect__option--highlight {
      .material-design-icon__svg,
      .level-name {
        color: white;
      }
    }
  }
}
</style>
