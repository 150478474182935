<template>
  <div class="indexed-records-form advanced-form unified-search-form" v-if="isAdvanced">
    <div class="filter form-item inline" v-if="showLangSelector">
      <label class="label">Search in: </label>
      <radio-select
        :options="languageOptions"
        :selected="lang"
        name="language-radio"
        @change="onLanguageChange"
      ></radio-select>
    </div>
    <div class="filter-group form-item">
      <div class="filter">
        <label class="input-label">First Name</label>
        <input
          v-capitalize
          :value="formData.first_name"
          :placeholder="firstNamePlaceholder"
          :class="{'has-error': errors.first_name}"
          class="input"
          @input="onInputChange('first_name', $event)"
          @keyup.enter="onInputEnter"
        />
        <div class="fuzzy-checkbox">
          <input v-model="formData.with_fuzzy_first_name" type="checkbox" id="fuzzy-firstname" />
          <label for="fuzzy-firstname">Search for similar first names</label>
        </div>
      </div>
      <div class="filter">
        <label class="input-label">Last Name</label>
        <input
          v-capitalize
          v-show="enLangSelected"
          :value="formData.surname"
          :placeholder="lastNamePlaceholder"
          :class="{'has-error': errors.surname}"
          class="input"
          @input="onInputChange('surname', $event)"
          @keyup.enter="onInputEnter"
        />
        <surname-autocomplete-select
          v-show="!enLangSelected"
          :placeholder="lastNamePlaceholder"
          :value="formData.surname"
          :has-error="errors.surname"
          @change="onSurnameChange"
        ></surname-autocomplete-select>
        <div class="fuzzy-checkbox">
          <input v-model="formData.with_fuzzy_surname" type="checkbox" id="fuzzy-surname" />
          <label for="fuzzy-surname">Search for similar last names</label>
        </div>
      </div>
    </div>
    <div v-if="errors.first_name || errors.surname" class="error">
      {{ langErrorLabel }}<a @click="switchLangAndSearch">{{ langErrorLinkLabel }}</a>
    </div>

    <div class="filter-group form-item align-bottom">
      <place-input
        class="filter full-width"
        label="Place your ancestor might have lived"
        :placeholder="placeFieldPlaceholder"
        :disabled="placeFieldDisabled"
        :value="formData.auto_place || {}"
        :allow-free-input="true"
        :show-default-options="false"
        :only-ancestral-places="false"
        :error="errors.auto_place"
        multiselect-classes="bordered"
        label-classes="input-label"
        @select="onPlaceFieldSelect"
      ></place-input>

      <div class="filter small">
        <label for="record_date" class="input-label">Year</label>
        <div>
          <input
            id="record_date"
            :value="formData.record_year"
            placeholder="YYYY"
            class="input record-year"
            @keyup.enter="onInputEnter"
            @input="onInputChange('record_year', $event)"
          />
        </div>
      </div>
    </div>

    <div class="section-heading text-lg">Gender</div>

    <div class="filter form-item">
      <div class="field radio">
        <template v-for="{name, value} in genderOptions">
          <input :id="`gender-${value}`" type="radio" name="gender-radio" :value="value" v-model="formData.gender" />
          <label :for="`gender-${value}`">{{ name }}</label>
        </template>
      </div>
    </div>

    <div class="section-heading text-lg">Birth</div>

    <div class="filter-group form-item">
      <place-input
        class="filter full-width"
        label="Birth Place"
        id="birth-location"
        placeholder="All Locations"
        :value="formData.birth_location || {}"
        :show-default-options="false"
        :only-ancestral-places="false"
        multiselect-classes="bordered"
        label-classes="input-label"
        @select="onBirthLocationSelect"
      ></place-input>

      <div class="filter small">
        <label for="birth_date" class="input-label">Birth Year</label>
        <div>
          <input
            id="birth_date"
            :value="formData.birth_year"
            placeholder="YYYY"
            maxlength="4"
            size="4"
            :class="{'has-error': !!errors.birth_year}"
            class="input"
            @keyup.enter="onInputEnter"
            @input="onInputChange('birth_year', $event)"
          />
        </div>
        <div v-if="errors.birth_year" class="error">{{ errors.birth_year }}</div>
      </div>
    </div>

    <div class="section-heading text-lg">Death</div>

    <div class="filter-group form-item">
      <place-input
        class="filter full-width"
        label="Death Place"
        id="death-location"
        placeholder="All Locations"
        :value="formData.death_location || {}"
        :show-default-options="false"
        :only-ancestral-places="false"
        multiselect-classes="bordered"
        label-classes="input-label"
        @select="onDeathLocationSelect"
      ></place-input>

      <div class="filter small">
        <label for="death_date" class="input-label">Death Year</label>
        <div>
          <input
            id="death_date"
            :value="formData.death_year"
            placeholder="YYYY"
            maxlength="4"
            size="4"
            :class="{'has-error': !!errors.death_year}"
            class="input"
            @keyup.enter="onInputEnter"
            @input="onInputChange('death_year', $event)"
          />
        </div>
        <div v-if="errors.death_year" class="error">{{ errors.death_year }}</div>
      </div>
    </div>

    <relatives-form-block
      :relatives="formData.relatives"
      @add-relative="onAddRelative"
      @change-relative="onChangeRelative"
      @remove-relative="onRemoveRelative"
      @submit="onInputEnter"
    ></relatives-form-block>

    <div class="section-heading text-lg">Migration</div>

    <div class="filter-group form-item has-help-text">
      <place-input
        class="filter full-width"
        label="Place"
        id="migration-location"
        placeholder="All Locations"
        :value="migrationFact.place || {}"
        :show-default-options="false"
        :only-ancestral-places="false"
        multiselect-classes="bordered"
        label-classes="input-label"
        @select="onMigrationLocationSelect"
      ></place-input>

      <div class="filter small">
        <label for="migration_date" class="input-label">Year</label>
        <div>
          <input
            id="migration_date"
            :value="migrationFact.year"
            placeholder="YYYY"
            maxlength="4"
            size="4"
            class="input"
            @keyup.enter="onInputEnter"
            @input="onMigrationYearInput"
          />
        </div>
      </div>
    </div>
    <div class="form-item-help-text text-sm">You can provide arrival or departure place</div>

    <div class="section-heading text-lg">Residence</div>

    <place-input
      class="filter form-item"
      label="Residence Place"
      placeholder="All Locations"
      :value="formData.residence_location || {}"
      :show-default-options="false"
      :only-ancestral-places="false"
      id="residence-location"
      multiselect-classes="bordered"
      label-classes="input-label"
      @select="onValueChange('residence_location', $event)"
    ></place-input>

    <facts-form-block
      :options="factsOptions"
      :facts="formData.facts || []"
      @add-fact="onAddFact"
      @change-fact="onFactChange"
      @remove-fact="onRemoveFact"
      @submit="onInputEnter"
    ></facts-form-block>

    <div class="section-heading text-lg"></div>

    <div class="filter-group form-item">
      <place-input
        class="filter"
        label="Source Location"
        id="source-location"
        placeholder="All Locations"
        :value="formData.source_location || {}"
        :show-default-options="false"
        :only-ancestral-places="false"
        multiselect-classes="bordered"
        label-classes="input-label"
        @select="onSourceLocationSelect"
      ></place-input>

      <div class="filter">
        <label for="source-types" class="input-label">Record Type</label>
        <single-option-select
          id="source-types"
          placeholder="All Record Types"
          :value="formData.source_types"
          :options="recordTypeOptions"
          @select="onValueChange('source_types', $event)"
        ></single-option-select>
      </div>
    </div>

    <div class="filter form-item">
      <label for="categories" class="input-label">Category</label>
      <single-option-select
        id="categories"
        placeholder="All Categories"
        value-field="id"
        :value="formData.category_id"
        :options="categoriesOptions"
        @select="onValueChange('category_id', $event)"
      ></single-option-select>
    </div>

    <div class="button-container">
      <mcr-button v-if="showCancel" class="white" @click="$emit('cancel')">Cancel</mcr-button>
      <mcr-button class="search-button" @click="onSearchClick">
        <search-icon class="search-icon" :size="20"></search-icon>
        <span>Search</span>
      </mcr-button>
    </div>
  </div>
  <div class="indexed-records-form simple-form unified-search-form" v-else>
    <div class="filter form-item inline" v-if="showLangSelector">
      <label class="label">Search in:</label>
      <radio-select
        :options="languageOptions"
        :selected="lang"
        name="language-radio"
        @change="onLanguageChange"
      ></radio-select>
    </div>
    <div class="filter-group align-bottom" :class="{'form-item': !isSimpleFormSmall}">
      <div class="filter">
        <label class="input-label">First Name</label>
        <input
          v-capitalize
          :value="formData.first_name"
          :placeholder="firstNamePlaceholder"
          :class="{'has-error': errors.first_name}"
          class="input"
          @input="onInputChange('first_name', $event)"
          @keyup.enter="onInputEnter"
        />
      </div>
      <div class="filter">
        <label class="input-label">Last Name</label>
        <input
          v-capitalize
          v-show="enLangSelected"
          :value="formData.surname"
          :placeholder="lastNamePlaceholder"
          :class="{'has-error': errors.surname}"
          class="input"
          @input="onInputChange('surname', $event)"
          @keyup.enter="onInputEnter"
        />
        <surname-autocomplete-select
          v-show="!enLangSelected"
          :placeholder="lastNamePlaceholder"
          :value="formData.surname"
          :has-error="errors.surname"
          @change="onSurnameChange"
        ></surname-autocomplete-select>
      </div>
      <mcr-button class="search-button small" @click="onSearchClick" v-if="isSimpleFormSmall">
        <search-icon class="search-icon" :size="20"></search-icon>
        <span>Search</span>
      </mcr-button>
    </div>
    <div v-if="errors.first_name || errors.surname" class="error">
      {{ langErrorLabel }}<a @click="switchLangAndSearch">{{ langErrorLinkLabel }}</a>
    </div>

    <div class="filter-group align-bottom" v-if="!isSimpleFormSmall">
      <place-input
        class="filter"
        label="Place your ancestor might have lived"
        :placeholder="placeFieldPlaceholder"
        :disabled="placeFieldDisabled"
        :value="formData.auto_place || {}"
        :show-default-options="false"
        :only-ancestral-places="false"
        :allow-free-input="true"
        :error="errors.auto_place"
        multiselect-classes="bordered"
        label-classes="input-label"
        @select="onPlaceFieldSelect"
      ></place-input>

      <div class="filter-with-button">
        <div class="filter small">
          <label for="record_date" class="input-label">Year</label>
          <div>
            <input
              id="record_date"
              :value="formData.record_year"
              placeholder="YYYY"
              class="input record-year"
              @keyup.enter="onInputEnter"
              @input="onInputChange('record_year', $event)"
            />
          </div>
        </div>
        <mcr-button class="search-button" @click="onSearchClick">
          <search-icon class="search-icon" :size="20"></search-icon>
          <span>Search</span>
        </mcr-button>
      </div>
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import SingleOptionSelect from '@common/elements/filters/SingleOptionSelect';
import RadioSelect from '@common/elements/filters/radioSelect';
import PlaceInput from '@common/elements/inputs/PlaceInput';
import SurnameAutocompleteSelect from '@common/elements/inputs/SurnameAutocompleteSelect';
import FactsFormBlock from '@common/pages/searches/forms/FactsFormBlock';
import RelativesFormBlock from '@common/pages/searches/forms/RelativesFormBlock';
import {getFactsForSearch, isIndexedRecordsFormPlaceFieldDisabled} from '@common/pages/searches/helpers/utils';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageIdentifier, getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {FACT_CATEGORY_IMMIGRATION} from '@common/utils/consts.search';
import {getRandomString, isChineseText} from '@common/utils/utils';
import {sortBy} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import upperFirst from 'lodash/upperFirst';
import SearchIcon from 'vue-material-design-icons/Magnify';
import {mapGetters} from 'vuex';

const EN = 'en';
const CN = 'cn';
const LANG_MAPPING = {[EN]: 'English', [CN]: 'Chinese'};

export default {
  props: {
    initAdvanced: {type: Boolean, default: false},
    initLang: {type: String, default: EN}, // en or cn
    showCancel: {type: Boolean, default: false},
    showLangSelector: {type: Boolean, default: true},
    isSimpleFormSmall: {type: Boolean, default: false},
    clearForm: {type: Boolean, default: false},
  },
  data() {
    return {
      formData: {},
      lang: this.initLang,
      errors: {},
      isAdvanced: this.initAdvanced,
    };
  },
  created() {
    this.formData = {...this.getInitialFormData()};
    this.initSwitchLang();
  },
  computed: {
    ...mapGetters(['searchAllRecordsFormState', 'searchAllRecordsOptionsState']),
    languageOptions() {
      return [
        {id: EN, text: 'English'},
        {id: CN, text: 'Chinese 中文'},
      ];
    },
    firstNamePlaceholder() {
      const mapping = {[EN]: 'Will, Sam or Yuen', [CN]: 'eg. 添福'};
      return mapping[this.lang];
    },
    lastNamePlaceholder() {
      const mapping = {[EN]: 'Li, Chen, or Wang', [CN]: '陳, 黄, 劉'};
      return mapping[this.lang];
    },
    enLangSelected() {
      return this.lang === EN;
    },
    selectedLangLabel() {
      return LANG_MAPPING[this.lang];
    },
    notSelectedLangLabel() {
      const otherLang = this.lang === EN ? CN : EN;
      return LANG_MAPPING[otherLang];
    },
    langErrorLinkLabel() {
      if (this.errors.first_name && this.errors.surname) {
        return `search in ${this.errors.notSelectedLang}`;
      }
      return `proceed anyway?`;
    },
    langErrorLabel() {
      return `You are currently searching ${this.errors.selectedLang} records, `;
    },
    genderOptions() {
      let genders = [...(this.searchAllRecordsOptionsState.genders || [])];
      genders.push({name: 'Unknown', value: null});
      return genders;
    },
    categoriesOptions() {
      return this.searchAllRecordsOptionsState.categories
        ? [...this.searchAllRecordsOptionsState.categories].sort((a, b) => a.priority - b.priority)
        : [];
    },
    recordTypeOptions() {
      return this.searchAllRecordsOptionsState.source_types
        ? [...this.searchAllRecordsOptionsState.source_types].sort((a, b) => a.name.localeCompare(b.name)) || []
        : [];
    },
    sourceCountriesOptions() {
      return this.searchAllRecordsOptionsState.source_countries
        ? sortBy(
            this.searchAllRecordsOptionsState.source_countries.map(item => ({value: item.id, name: item.name})),
            'name'
          )
        : [];
    },
    factsOptions() {
      return this.searchAllRecordsOptionsState.facts || [];
    },
    placeFieldDisabled() {
      return isIndexedRecordsFormPlaceFieldDisabled(this.formData);
    },
    placeFieldPlaceholder() {
      if (this.placeFieldDisabled) {
        return 'Clear the place selections below to use this field';
      }
      return 'All Places';
    },
    migrationFact() {
      const facts = this.formData.facts.filter(fact => fact.fact_category === FACT_CATEGORY_IMMIGRATION);
      return facts[0];
    },
  },
  methods: {
    getInitialFormData() {
      return this.getInitData(this.searchAllRecordsFormState, this.clearForm, ['category_id']);
    },
    getInitData(initData, clear, keepFields) {
      let initFormData = {...initData};
      if (clear) {
        Object.keys(initFormData).forEach(key => {
          if (!keepFields.includes(key)) {
            initFormData[key] = null;
          }
        });
      }
      initFormData.facts = this.getInitialFacts(clear ? [] : initFormData.facts);
      initFormData.relatives = clear ? [] : initFormData.relatives || [];
      return initFormData;
    },
    getInitialFacts(facts) {
      facts = facts || [];
      const migrationFacts = facts.filter(fact => fact.fact_category === FACT_CATEGORY_IMMIGRATION);
      const migrationFact = migrationFacts.length ? migrationFacts[0] : null;
      if (!migrationFact) {
        return [{fact_category: FACT_CATEGORY_IMMIGRATION, formId: getRandomString()}, ...facts];
      }
      return facts;
    },
    initSwitchLang() {
      if (isChineseText(this.formData.first_name) || isChineseText(this.formData.surname)) {
        this.lang = CN;
      }
    },
    onSearchClick() {
      const isValid = this.validateFilters();
      if (isValid) {
        this.runSearch();
      }
    },
    getFormDataForSearch() {
      return {...this.formData, facts: getFactsForSearch(this.formData)};
    },
    runSearch() {
      this.$store.commit('mutateSearchAllRecordsFormState', this.getFormDataForSearch());
      this.$emit('submit');
    },
    onLanguageChange(lang) {
      this.lang = lang;
      this.errors.first_name = false;
      this.errors.surname = false;
      AnalyticsAmplitudeHandler.trackClickLanguageSelectorEvent(
        getRoutePageName(this.$route),
        getRoutePageIdentifier(this.$route),
        'indexed',
        lang
      );
    },
    validateFilters() {
      if (!this.formData.first_name) {
        this.formData.with_fuzzy_first_name = false;
      }
      if (!this.formData.surname) {
        this.formData.with_fuzzy_surname = false;
      }
      this.errors = {};
      if (!this.isValidByLanguage(this.formData.first_name)) {
        this.errors.first_name = true;
      }
      if (!this.isValidByLanguage(this.formData.surname)) {
        this.errors.surname = true;
      }
      if (this.checkIsNan(this.formData.birth_year)) {
        this.errors['birth_year'] = 'A number is required';
      }
      if (this.checkIsNan(this.formData.death_year)) {
        this.errors['death_year'] = 'A number is required';
      }
      if (this.errors.first_name || this.errors.surname) {
        this.errors.selectedLang = this.selectedLangLabel;
        this.errors.notSelectedLang = this.notSelectedLangLabel;
      }
      let isValid = isEmpty(this.errors);
      if (!isValid) {
        this.$nextTick(() => {
          this.$emit('validation-error', this.errors);
        });
        return isValid;
      }

      // facts always have a migration fact, check there are more than 1 fact, or migration is filled in
      isValid = Object.keys(this.formData).some(key => {
        const item = this.formData[key];
        if (key === 'facts') {
          return item.length > 1 || item[0].place_id || item[0].year;
        }
        if (key === 'category_id') {
          return false;
        }
        return Array.isArray(item) ? item.length : !isEmpty(item);
      });

      if (!isValid) {
        this.$toasted.error('Please search for at least one name or filter');
      }
      return isValid;
    },
    isValidByLanguage(value) {
      if (!value) {
        return true;
      }
      const isChinese = isChineseText(value);
      return (this.lang === CN && isChinese) || (this.lang === EN && !isChinese);
    },
    onInputChange(fieldName, event) {
      this.onValueChange(fieldName, event.target.value.trim());
    },
    onSurnameChange(value) {
      this.onValueChange('surname', upperFirst(value));
    },
    onValueChange(fieldName, fieldValue) {
      this.$set(this.formData, fieldName, fieldValue);

      if (this.placeFieldDisabled) {
        this.$set(this.formData, 'auto_place', {});
      }
    },
    onPlaceFieldSelect(value) {
      this.onValueChange('auto_place', value);
    },
    onMigrationLocationSelect(value) {
      this.onFactChange({fact: this.migrationFact, fieldName: 'place', newValue: value});
    },
    onMigrationYearInput(event) {
      this.onFactChange({fact: this.migrationFact, fieldName: 'year', newValue: event.target.value});
    },
    onBirthLocationSelect(value) {
      this.onValueChange('birth_location', value);
    },
    onDeathLocationSelect(value) {
      this.onValueChange('death_location', value);
    },
    onSourceLocationSelect(value) {
      this.onValueChange('source_location', value);
    },
    onFactChange({fact, fieldName, newValue}) {
      const index = this.formData.facts.findIndex(f => fact.formId === f.formId);
      const newFact = {...this.formData.facts[index], [fieldName]: newValue};
      if (fieldName === 'place') {
        this.$set(newFact, 'place_id', newValue.id);
      }
      if (fieldName === 'cemetery') {
        this.$set(newFact, 'cemetery_id', newValue.cemetery_id);
      }
      this.$set(this.formData.facts, index, newFact);
    },
    onAddFact(factType) {
      const facts = this.formData.facts;
      const newFact = {fact_type: factType, formId: getRandomString()};
      this.$set(this.formData, 'facts', [...facts, newFact]);
    },
    onRemoveFact(fact) {
      const newFacts = this.formData.facts.filter(f => fact.formId !== f.formId);
      this.$set(this.formData, 'facts', newFacts);
    },
    onAddRelative(relationType) {
      const relatives = this.formData.relatives;
      const newRelative = {relation_type: relationType, formId: getRandomString()};
      this.$set(this.formData, 'relatives', [...relatives, newRelative]);
    },
    onChangeRelative({relative, fieldName, newValue}) {
      const index = this.formData.relatives.findIndex(r => relative.formId === r.formId);
      const newRelative = {...this.formData.relatives[index], [fieldName]: newValue};
      this.$set(this.formData.relatives, index, newRelative);
    },
    onRemoveRelative(relative) {
      const newRelatives = this.formData.relatives.filter(r => relative.formId !== r.formId);
      this.$set(this.formData, 'relatives', newRelatives);
    },
    switchLangAndSearch() {
      this.lang = this.lang === EN ? CN : EN;
      this.runSearch();
    },
    onInputEnter() {
      this.onSearchClick();
    },
    checkIsNan(value) {
      return value && isNaN(+value);
    },
    setIsAdvanced(value) {
      this.isAdvanced = value;
      if (!value) {
        this.clearAdvancedFilters();
      }
    },
    clearAdvancedFilters() {
      this.formData = this.getInitData(this.formData, true, [
        'category_id',
        'first_name',
        'surname',
        'auto_place',
        'record_year',
      ]);
    },
    mutateFormData(newObject) {
      this.formData = {...this.formData, ...newObject};
    },
  },
  name: 'IndexedRecordsForm',
  components: {
    RelativesFormBlock,
    FactsFormBlock,
    SurnameAutocompleteSelect,
    McrButton,
    RadioSelect,
    SingleOptionSelect,
    PlaceInput,
    SearchIcon,
  },
};
</script>

<style lang="scss" scoped>
@import '~@common/pages/searches/styles/form.scss';
</style>
