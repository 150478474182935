import { render, staticRenderFns } from "./imagesGalleryOverlayContainer.vue?vue&type=template&id=137d6d37&scoped=true&"
import script from "./imagesGalleryOverlayContainer.vue?vue&type=script&lang=js&"
export * from "./imagesGalleryOverlayContainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "137d6d37",
  null
  
)

export default component.exports