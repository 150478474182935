import {FACT_CATEGORY_IMMIGRATION, VALIDATE_FACT_FIELD_SET} from '@common/utils/consts.search';

export function isIndexedRecordsFormPlaceFieldDisabled(formData) {
  const facts = formData.facts.filter(fact => fact.fact_category === FACT_CATEGORY_IMMIGRATION);
  const migrationFact = facts[0];
  const birth = formData.birth_location && formData.birth_location.id;
  const death = formData.death_location && formData.death_location.id;
  const residence = formData.residence_location && formData.residence_location.id;
  const sourceLocation = formData.source_location && formData.source_location.id;
  const migrationPlace = migrationFact && migrationFact.place && migrationFact.place.id;
  return Boolean(birth || death || residence || sourceLocation || migrationPlace);
}

export function isSourcesFormPlaceFieldDisabled(formData) {
  const ancestralPlaceId = formData.ancestral_place && formData.ancestral_place.id;
  return Boolean(formData.country || ancestralPlaceId);
}

export function getFactsForSearch(formData) {
  return formData.facts.filter(fact => {
    return VALIDATE_FACT_FIELD_SET.some(fieldName => fact[fieldName]);
  });
}

export function getClanName(clan) {
  const nameCh =
    clan.name_hant && clan.name_ch !== clan.name_hant ? `${clan.name_ch} / ${clan.name_hant}` : clan.name_ch;
  return [clan.pinyin || '', nameCh].join(' ').trim() || clan.name;
}
