<template>
  <div class="metadata-table record-metadata-table">
    <div class="text-lg bold title">
      <span>Record Details</span>
    </div>

    <stand-out-block v-if="isDataProtected" class="warning">
      This record's data is hidden because it may refer to a living person
    </stand-out-block>
    <div class="table text-md" v-else>
      <div class="table-row" v-if="otherMentions.length">
        <div class="cell cell-name">Other on this Record</div>
        <div class="cell">
          <router-link
            class="name"
            v-for="person in shownOtherMentions"
            :to="getRelatedPersonRoute(person)"
            :key="person.id"
            >{{ person.full_name || 'Unknown' }}</router-link
          >
          <span v-if="moreOtherMentionsLength">
            and {{ moreOtherMentionsLength }} more persons (<a @click="showAllOtherMentions">show all</a>)
          </span>
        </div>
      </div>
      <div class="table-row" v-if="surnamesEn.length">
        <div class="cell cell-name">Surname (en)</div>
        <div class="cell">
          <span class="name" v-for="name in surnamesEn" :key="name.value">
            <router-link :to="getSurnameRoute(name.value)">{{ name.value }}</router-link>
            <span v-if="name.type_display"> ({{ name.type_display }})</span>
          </span>
        </div>
      </div>
      <div class="table-row" v-if="firstNamesEn.length">
        <div class="cell cell-name">Given name (en)</div>
        <div class="cell">
          <span class="name" v-for="name in firstNamesEn">{{ name.value }}</span>
        </div>
      </div>
      <div class="table-row" v-if="surnamesCh.length">
        <div class="cell cell-name">Surname (cn)</div>
        <div class="cell">
          <span class="name" v-for="name in surnamesCh" :key="name.value">
            <router-link :to="getSurnameRoute(name.value)">{{ name.value }}</router-link>
            <span v-if="name.type_display"> ({{ name.type_display }})</span>
          </span>
        </div>
      </div>
      <div class="table-row" v-if="firstNamesCh.length">
        <div class="cell cell-name">Given name (cn)</div>
        <div class="cell">
          <span class="name" v-for="name in firstNamesCh">{{ name.value }}</span>
        </div>
      </div>
      <div class="table-row" v-if="otherNames.length">
        <div class="cell cell-name">Other names</div>
        <div class="cell">
          <span class="name" v-for="name in otherNames">{{ name.value }}</span>
        </div>
      </div>
      <template v-for="spouse in spousesMetaData">
        <div class="table-row" :key="spouse.code">
          <div class="cell cell-name text-capitalize">{{ spouse.relation }} {{ spouse.relation_type }}</div>
          <div class="cell">
            <span class="name"
              ><router-link :to="getRelatedPersonRoute(spouse)">{{ spouse.full_name || 'Unknown' }}</router-link></span
            >
          </div>
        </div>
        <div class="table-row" v-if="spouse.start_date">
          <div class="cell cell-name is-nested">Marriage Date</div>
          <div class="cell">
            <span class="name">{{ spouse.start_date }}</span>
          </div>
        </div>
        <div class="table-row" v-if="spouse.start_place">
          <div class="cell cell-name is-nested">Marriage Place</div>
          <div class="cell">
            <span class="name">
              <router-link :to="getPlaceDetailRoute(spouse.start_place_id)" v-if="spouse.start_place_id">{{
                spouse.start_place
              }}</router-link>
              <template v-else>{{ spouse.start_place }}</template>
            </span>
          </div>
        </div>
        <div class="table-row" v-if="spouse.end_date">
          <div class="cell cell-name is-nested">Divorce Date</div>
          <div class="cell">
            <span class="name">{{ spouse.end_date }}</span>
          </div>
        </div>
        <div class="table-row" v-if="spouse.end_place">
          <div class="cell cell-name is-nested">Divorce Place</div>
          <div class="cell">
            <span class="name">
              <router-link :to="getPlaceDetailRoute(spouse.end_place_id)" v-if="spouse.end_place_id">{{
                spouse.end_place
              }}</router-link>
              <template v-else>{{ spouse.end_place }}</template>
            </span>
          </div>
        </div>
      </template>
      <div class="table-row" v-if="mention.birth_location && mention.birth_location.display_text">
        <div class="cell cell-name">Birth place</div>
        <div class="cell">
          <router-link v-if="birthLocationRoute" :to="birthLocationRoute">{{
            mention.birth_location.display_text
          }}</router-link>
          <span v-else>{{ mention.birth_location.display_text }}</span>
        </div>
      </div>
      <div class="table-row" v-if="mention.death_location && mention.death_location.display_text">
        <div class="cell cell-name">Death place</div>
        <div class="cell">
          <router-link v-if="deathLocationRoute" :to="deathLocationRoute">{{
            mention.death_location.display_text
          }}</router-link>
          <span v-else>{{ mention.death_location.display_text }}</span>
        </div>
      </div>
      <div class="table-row" v-if="mention.gender_display && mention.gender_display !== 'Unknown'">
        <div class="cell cell-name">Sex</div>
        <div class="cell">
          <span>{{ mention.gender_display }}</span>
        </div>
      </div>
      <div class="table-row" v-if="birthDateFormatted">
        <div class="cell cell-name">Birth date</div>
        <div class="cell">
          <span>{{ birthDateFormatted }}</span>
        </div>
      </div>
      <div class="table-row" v-if="deathDateFormatted">
        <div class="cell cell-name">Death date</div>
        <div class="cell">
          <span>{{ deathDateFormatted }}</span>
        </div>
      </div>
      <div class="table-row" v-if="mention.residence_location && mention.residence_location.length">
        <div class="cell cell-name">Residence</div>
        <div class="cell">
          <div v-for="residence in mention.residence_location" class="name">
            <router-link v-if="residence.place_id" :to="getResidenceRoute(residence)">{{
              getFormattedResidenceText(residence)
            }}</router-link>
            <span v-else>{{ getFormattedResidenceText(residence) }}</span>
          </div>
        </div>
      </div>
      <div class="table-row" v-if="mention.generation_number">
        <div class="cell cell-name">
          <mcr-wiki-glossary-link naturalId="generation-number" :is-help-text="true">
            Generation
          </mcr-wiki-glossary-link>
        </div>
        <div class="cell">
          <span>{{ mention.generation_number }}</span>
        </div>
      </div>
      <template v-for="(data, key) in normalizedAdditionalInfo">
        <div class="table-row" :key="key">
          <div class="cell cell-name">{{ data.label }}</div>
          <div class="cell">
            <span v-for="item in data.value" class="name">
              <router-link :to="item.route" v-if="item.route">{{ item.name }}</router-link>
              <span v-else>{{ item.name }}</span>
            </span>
          </div>
        </div>
      </template>
      <template v-for="fact in factsDisplay">
        <div class="table-row" :key="fact.id">
          <div class="cell cell-name">{{ fact.factType.label }}</div>
          <div class="cell">
            <div>
              <span>{{ fact.value }}{{ fact.valueSeparator }}</span>
              <span>{{ fact.dates }}{{ fact.placeSeparator }}</span>
              <router-link :to="fact.placeRoute" v-if="fact.placeRoute">{{ fact.placeText }}</router-link>
              <span v-else>{{ fact.placeText }}</span>
            </div>
            <a
              v-if="source.external_source_url && fact.factType.id === 'original_title'"
              :href="source.external_source_url"
              class="external-link"
              target="_blank"
            >
              <span>View record details on partner site</span>
              <link-icon class="link-icon" :size="18"></link-icon>
            </a>
          </div>
        </div>
        <div class="table-row" :key="fact.id + 'cemetery'" v-if="fact.cemetery">
          <div class="cell cell-name is-nested">Cemetery</div>
          <div class="cell">
            <router-link :key="fact.cemetery.object_id" :to="getCemeteryRoute(fact.cemetery)">{{
              fact.cemetery.name
            }}</router-link>
          </div>
        </div>
        <div class="table-row" v-for="infoItem in fact.info" :key="fact.id + 'info' + infoItem.key">
          <div class="cell cell-name is-nested">{{ infoItem.label }}</div>
          <div class="cell">
            <span>{{ infoItem.value }}</span>
          </div>
        </div>
      </template>
      <div class="table-row" v-if="showSource">
        <div class="cell cell-name">Source</div>
        <div class="cell">
          <router-link :to="sourceRoute">{{ sourceTitle }}</router-link>
        </div>
      </div>
      <div class="table-row" v-if="source.clan_pedigree_id && source.clans && source.clans.length">
        <div class="cell cell-name">Zupu Clan</div>
        <div class="cell">
          <link-surname-page-family :clans="source.clans" :show-family="false"></link-surname-page-family>
        </div>
      </div>
      <div
        class="table-row"
        v-if="source.clan_pedigree_id && source.ancestral_places && source.ancestral_places.length"
      >
        <div class="cell cell-name">Zupu Place</div>
        <div class="cell">
          <link-place-page v-for="place in source.ancestral_places" :key="place.id" :place="place" class="name">{{
            place.pinyin
          }}</link-place-page>
        </div>
      </div>
      <div class="table-row" v-if="showSource && source.publication_year">
        <div class="cell cell-name">{{ publicationYearLabel }}</div>
        <div class="cell">
          {{ source.publication_year }}
        </div>
      </div>
      <div class="table-row" v-if="source.cemeteries && source.cemeteries.length">
        <div class="cell cell-name">Cemetery</div>
        <div class="cell">
          <router-link
            v-for="cemetery in source.cemeteries"
            class="name"
            :key="cemetery.object_id"
            :to="getCemeteryRoute(cemetery)"
            >{{ cemetery.name }}</router-link
          >
        </div>
      </div>
      <div class="table-row" v-if="source.associations && source.associations.length">
        <div class="cell cell-name">Association</div>
        <div class="cell">
          <span v-for="association in source.associations" class="name" :key="association.id">{{
            association.name_en
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mcrWikiGlossaryLink from '@common/elements/glossary/mcrWikiGlossaryLink.vue';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import LinkPlacePage from '@common/elements/links/linkPlacePage.vue';
import linkSurnamePageFamily from '@common/elements/links/linkSurnamePageFamily.vue';
import {sourceTypes} from '@common/utils/consts.source';
import {formatApproximateDate, getFormattedResidenceText} from '@common/utils/utils';
import {getCemeteryDetailRoute, getPersonRecordDetailRoute, getPlaceDetailRoute} from '@common/utils/utils.routes';
import pickBy from 'lodash/pickBy';
import LinkIcon from 'vue-material-design-icons/OpenInNew';

export default {
  props: {
    firstNamesEn: Array,
    surnamesEn: Array,
    firstNamesCh: Array,
    surnamesCh: Array,
    otherNames: Array,
    mention: Object,
    source: Object,
    otherMentions: Array,
    isDataProtected: Boolean,
  },
  created() {
    this.processAdditionalInfo(this.source.info);
  },
  data() {
    return {
      normalizedAdditionalInfo: {},
      otherMentionsTrimmed: true,
      otherMentionsTrimmedLength: 10,
    };
  },
  computed: {
    birthLocationRoute() {
      if (this.mention.birth_location && this.mention.birth_location.place_id) {
        return getPlaceDetailRoute(this.mention.birth_location.place_id, '');
      }
    },
    deathLocationRoute() {
      if (this.mention.death_location && this.mention.death_location.place_id) {
        return getPlaceDetailRoute(this.mention.death_location.place_id, '');
      }
    },
    birthDateFormatted() {
      return this.mention.birth_date ? formatApproximateDate(this.mention.birth_date) : '';
    },
    deathDateFormatted() {
      return this.mention.death_date ? formatApproximateDate(this.mention.death_date) : '';
    },
    shownOtherMentions() {
      if (this.otherMentionsTrimmed) {
        return this.otherMentions.slice(0, this.otherMentionsTrimmedLength);
      }
      return this.otherMentions;
    },
    moreOtherMentionsLength() {
      return this.otherMentions.length - this.shownOtherMentions.length;
    },
    factsDisplay() {
      return this.mention.facts
        ? this.mention.facts.map(fact => {
            const dates = this.getDisplayFactDates(fact.start_date, fact.end_date);
            return {
              id: fact.id,
              factType: fact.fact_type,
              info: fact.info,
              value: fact.value || '',
              valueSeparator: fact.value && (dates || fact.place_display_text) ? ', ' : '',
              dates: dates,
              placeText: fact.place_display_text || '',
              placeRoute: fact.place_id ? getPlaceDetailRoute(fact.place_id, fact.place_display_text) : null,
              placeSeparator: dates && fact.place_display_text ? ' in ' : '',
              cemetery: fact.cemetery,
            };
          })
        : [];
    },
    showSource() {
      return this.source.type !== sourceTypes.CEMETERY;
    },
    sourceRoute() {
      if (this.source.clan_pedigree_id) {
        return {name: 'search-tool-zupu-detail', params: {zupuId: this.source.clan_pedigree_id}};
      }
      return {name: 'source-detail', params: {sourceId: this.source.id}};
    },
    sourceTitle() {
      return this.source.full_title;
    },
    publicationYearLabel() {
      return this.source.clan_pedigree_id ? 'Zupu Publication Year' : 'Source Date';
    },
    spousesMetaData() {
      return this.mention.related_persons
        .filter(p => {
          return p.relation_category === 'spouses' && (p.start_date || p.start_place || p.end_date || p.end_place);
        })
        .map(p => {
          return {
            ...p,
            start_date: p.start_date ? formatApproximateDate(p.start_date) : null,
            end_date: p.end_date ? formatApproximateDate(p.end_date) : null,
          };
        });
    },
  },
  methods: {
    processAdditionalInfo(info) {
      const config = {};
      for (let [key, data] of Object.entries(info)) {
        let value = data.value;
        if (!value.length) {
          continue;
        }
        if (!key.includes('business')) {
          continue;
        }
        if (typeof value[0] === 'string') {
          value = value.map(text => ({name: text}));
        }
        if (key.endsWith('_place')) {
          value = value
            .filter(item => !!item.name)
            .map(item => {
              return item.place_id ? {...item, route: getPlaceDetailRoute(item.place_id, item.name)} : item;
            });
        }
        config[key] = {...data, value};
      }
      this.normalizedAdditionalInfo = pickBy(config, data => data.value && data.value.length);
    },
    getSurnameRoute(word) {
      return {name: 'search-tool-surname-detail', query: {word}};
    },
    showAllOtherMentions() {
      this.otherMentionsTrimmed = false;
    },
    getRelatedPersonRoute(person) {
      return getPersonRecordDetailRoute(person.code, person.full_name);
    },
    getResidenceRoute(residence) {
      return getPlaceDetailRoute(residence.place_id, residence.display_text);
    },
    getDisplayFactDates(date1, date2) {
      const date1f = date1 ? formatApproximateDate(date1) : '';
      const date2f = date2 ? formatApproximateDate(date2) : '';
      if (date1f && date2f) {
        return `${date1f} to ${date2f}`;
      }
      return date1f || date2f;
    },
    getCemeteryRoute(cemetery) {
      return getCemeteryDetailRoute(cemetery.object_id, cemetery.name);
    },
    getFormattedResidenceText: getFormattedResidenceText,
    getPlaceDetailRoute: getPlaceDetailRoute,
  },
  components: {StandOutBlock, linkSurnamePageFamily, LinkPlacePage, mcrWikiGlossaryLink, LinkIcon},
  name: 'RecordMetadataTable',
};
</script>

<style scoped lang="scss">
@import 'styles/metadata-table';

.external-link {
  display: inline-block;
  margin-top: 10px;
  color: $neutral-500;

  .link-icon {
    display: inline;
    margin-left: 4px;
    &::v-deep .material-design-icon__svg {
      margin-top: -3px;
      vertical-align: middle;
    }
  }
}
.warning {
  color: $neutral-500;
  background: white;
}
</style>
