<template>
  <div class="place-input">
    <label class="label" :class="[labelClasses, {disabled: disabled}]" v-if="label">{{ label }}</label>
    <place-multiselect
      :value="value"
      :loading="loading"
      :search-query="searchQuery"
      :placeholder="placeholder"
      :id="id"
      ref="input"
      :options="options"
      track-by="id"
      label="full_address_en"
      :class="[classes, multiselectClasses]"
      :disabled="disabled"
      @open="searchDefaultOptions"
      @search-change="onSearchChange"
      @select="onPlaceSelect"
      @clear="clearPlace"
      @close="clearPlaceOptions"
    >
    </place-multiselect>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

import PlaceMultiselect from './PlaceMultiselect';

export default {
  props: {
    label: {type: String, default: ''},
    value: [Object, String],
    placeholder: {type: String, default: 'All Places'},
    error: {type: String, default: ''},
    showDefaultOptions: {type: Boolean, default: false},
    onlyAncestralPlaces: {type: Boolean, default: false},
    allowFreeInput: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    id: {type: String, default: 'place'},
    multiselectClasses: {type: String, default: ''},
    labelClasses: {type: String, default: ''},
  },
  data() {
    const optionHeight = 56;
    return {
      loading: false,
      options: [],
      searchQuery: '',
      optionHeight: optionHeight,
    };
  },
  computed: {
    classes() {
      return {'has-error': !!this.error};
    },
  },
  methods: {
    onSearchChange(query) {
      this.options = [];
      this.searchQuery = query;
      this.$store.commit('setPlaceAlternativesState', []);
      if (query) {
        this.loading = true;
        this.searchPlaces(query);
      } else {
        this.searchDefaultOptions();
      }
    },
    searchPlaces: debounce(function (query) {
      this.$store
        .dispatch('searchPlaceAlternativesAction', {query, onlyAncestralPlaces: this.onlyAncestralPlaces})
        .then(response => {
          if (this.searchQuery) {
            this.setOptionsFromResponse(response);
          }
          this.loading = false;
        });
    }, 300),
    searchDefaultOptions() {
      if (!this.showDefaultOptions) {
        return;
      }
      this.$store.dispatch('getCommonPlaceAlternativesAction').then(response => {
        this.setOptionsFromResponse(response);
      });
    },
    setOptionsFromResponse(response) {
      const freeInputOption = {
        isAddCustom: true,
        full_address_en: this.searchQuery,
        address_en: this.searchQuery,
        customOptionLabel: `Search "${this.searchQuery}" in by text match`,
      };
      if (response && response.length) {
        let options = response
          .filter(data => data.place_id)
          .map(data => ({
            id: data.place_id,
            full_address_en: data.display_text,
            address_en: data.address_en,
            parents: data.parents,
            level_name: data.level_name,
          }));
        if (
          this.allowFreeInput &&
          this.searchQuery &&
          !options.some(o => o.full_address_en.toLowerCase() === this.searchQuery.toLowerCase())
        ) {
          const index =
            options.length > this.$refs.input.visibleItemsCount - 1
              ? this.$refs.input.visibleItemsCount - 1
              : options.length;
          options.splice(index, 0, freeInputOption);
        }
        this.options = options;
      } else if (this.allowFreeInput) {
        this.options = [freeInputOption];
      } else {
        this.options = [];
      }
    },
    onPlaceSelect(value) {
      this.$emit('select', value);
    },
    clearPlace() {
      this.onPlaceSelect({});
    },
    clearPlaceOptions() {
      this.$store.commit('setPlaceAlternativesState', []);
    },
  },
  components: {PlaceMultiselect},
};
</script>

<style lang="scss" scoped>
.place-input {
  min-width: 0;
}
</style>
