import ActiveFilterTag from '@common/elements/filters/ActiveFilterTag';
import BlockBrowseZupus from '@common/pages/searches/BlockBrowseZupus';
import SearchResultsHelper from '@common/pages/searches/SearchResultsHelper';
import FormTabsBlockContainer from '@common/pages/searches/baseSearchAll/FormTabsBlockContainer';
import StickyEditButtons from '@common/pages/searches/buttons/StickyEditButtons';
import FiltersContainer from '@common/pages/searches/filters/FiltersContainer';
import FiltersContainerMobile from '@common/pages/searches/filters/FiltersContainerMobile';
import YourSearchForm from '@common/pages/searches/forms/YourSearchForm';
import searchPageComputed from '@common/pages/searches/helpers/searchPageComputed';
import searchPageMethods from '@common/pages/searches/helpers/searchPageMethods';
import EditSearchModal from '@common/pages/searches/modals/EditSearchModal';
import SearchError from '@common/pages/searches/results/SearchError';
import SearchResults from '@common/pages/searches/results/SearchResults';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {TAB_ID_FULL_TEXT, TAB_ID_INDEXED_RECORDS, TAB_ID_SOURCES} from '@common/utils/consts.search';
import isEmpty from 'lodash/isEmpty';
import {mapGetters} from 'vuex';

export default {
  metaInfo() {
    return {
      title: this.pageTitle,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content:
            "Explore the world's first collection of Overseas Chinese family history records. Search for your Chinese ancestors in our database of family tree books, burials, association and historical records.",
        },
      ],
    };
  },
  created() {
    this.handleEmptyQuery(this.$route.query);
    if (this.$route.query.source_id && this.$route.query.source_id !== this.sourceDetailsState.id) {
      this.$store.dispatch('fetchSourceDetailsAction', {id: this.$route.query.source_id});
    }
    if (
      this.$route.query.cemetery_id &&
      (!this.cemeteryState || this.$route.query.cemetery_id != this.cemeteryState.id)
    ) {
      this.$store.dispatch('fetchCemeteryDetailsAction', this.$route.query.cemetery_id);
    }
    if (isEmpty(this.searchAllRecordsOptionsState)) {
      this.$store.dispatch('fetchAllRecordsOptionsAction');
    }
    if (isEmpty(this.sourcesTextSearchOptionsState)) {
      this.$store.dispatch('fetchSourcesTextSearchOptionsAction');
    }
    if (isEmpty(this.searchAllSourcesOptionsState)) {
      this.$store.dispatch('fetchSearchSourcesOptionsAction');
    }
    this.init();
  },
  watch: {
    '$route.query': {
      handler: function (toQuery, fromQuery) {
        if (toQuery.results) {
          this.searchRan = true;
        }
        if (toQuery.tab !== this.activeTab) {
          this.forceActivateTab(toQuery.tab);
        }
        if (this.isRouteSwitchToAdvancedForm(toQuery, fromQuery)) {
          return;
        }
        this.handleEmptyQuery(toQuery);
        this.init();
      },
      deep: true,
    },
  },
  data() {
    return {
      loading: false,
      forceShowInitialLoading: false,
      searchRan: this.filtersInQuery(this.$route.query.tab),
      searchError: false,
      searchErrorText: '',
      errors: {},
      showAdvancedSearch: Boolean(this.$route.query.advanced_search),
      tabIdIndexedRecords: TAB_ID_INDEXED_RECORDS,
      tabIdFullText: TAB_ID_FULL_TEXT,
      tabIdSources: TAB_ID_SOURCES,
      activeTab: this.$route.query.tab,
      isClearEditSearch: false,
    };
  },
  computed: {
    ...searchPageComputed,
    ...mapGetters([
      'searchAllRecordsMetaState',
      'searchAllRecordsFormState',
      'searchAllRecordsListState',
      'searchAllRecordsOptionsState',
      'searchAllRecordsOptionsLoadingState',
      'searchCategoryState',
      'searchCategoryLoadingState',
      'sourceDetailsState',
      'cemeteryState',
      /* full text tab */
      'sourcesTextSearchOptionsState',
      'sourcesTextSearchFormState',
      'sourcesTextSearchMetaState',
      'sourcesTextSearchListState',
      /* sources tab */
      'searchAllSourcesMetaState',
      'searchAllSourcesFormState',
      'searchAllSourcesOptionsState',
      'searchAllSourcesListState',
    ]),
    initialLoading() {
      if (this.forceShowInitialLoading) {
        return true;
      }
      if (this.searchAllRecordsOptionsLoadingState || this.searchCategoryLoadingState || !this.activeTab) {
        return true;
      }
      // show loading until the query is initially parsed into the form;
      return this.$route.query.category_id ? !this.activeForm.category_id : false;
    },
    resultsList() {
      const mapping = {
        [this.tabIdIndexedRecords]: this.searchAllRecordsListState,
        [this.tabIdFullText]: this.sourcesTextSearchListState,
        [this.tabIdSources]: this.searchAllSourcesListState,
      };
      return this.activeTab ? mapping[this.activeTab] : [];
    },
    searchMeta() {
      const mapping = {
        [this.tabIdIndexedRecords]: this.searchAllRecordsMetaState,
        [this.tabIdFullText]: this.sourcesTextSearchMetaState,
        [this.tabIdSources]: this.searchAllSourcesMetaState,
      };
      return this.activeTab ? mapping[this.activeTab] : {};
    },
    options() {
      const mapping = {
        [this.tabIdIndexedRecords]: this.searchAllRecordsOptionsState,
        [this.tabIdFullText]: this.searchAllRecordsOptionsState,
        [this.tabIdSources]: this.searchAllSourcesOptionsState.facets,
      };
      return this.activeTab ? mapping[this.activeTab] : {};
    },
    sourceTypes() {
      if (!this.options) {
        return [];
      }
      const mapping = {
        [this.tabIdIndexedRecords]: this.options.source_types || [],
        [this.tabIdFullText]: this.options.source_types || [],
        [this.tabIdSources]: this.searchAllRecordsOptionsState.source_types || [],
      };
      return mapping[this.activeTab];
    },
    filters() {
      const mapping = {
        [this.tabIdIndexedRecords]: this.filtersIndexedRecords,
        [this.tabIdFullText]: this.filtersFullText,
        [this.tabIdSources]: this.filtersSources,
      };
      return this.activeTab ? mapping[this.activeTab] : {};
    },
    filtersFullText() {
      return {
        q: this.sourcesTextSearchFormState.q,
        last_name: this.sourcesTextSearchFormState.last_name,
        clan_name: this.sourcesTextSearchFormState.clan_name,
        country: this.sourcesTextSearchFormState.country,
        category_id: this.sourcesTextSearchFormState.category_id,
        source_type: this.sourcesTextSearchFormState.source_type,
        ...this.getAncestralPlacesFilters(this.sourcesTextSearchFormState),
        ancestral_place_id: this.sourcesTextSearchFormState.ancestral_place
          ? this.sourcesTextSearchFormState.ancestral_place.id
          : null,
        collection_id: this.sourcesTextSearchFormState.collection
          ? this.sourcesTextSearchFormState.collection.id
          : null,
        source_id: this.sourcesTextSearchFormState.source_id,
      };
    },
    filtersIndexedRecords() {
      const formAutoPlace = this.searchAllRecordsFormState.auto_place;
      let autoPlace = {
        auto_place_id: formAutoPlace ? formAutoPlace.id : null,
        auto_place_name:
          formAutoPlace && !formAutoPlace.id && formAutoPlace.address_en ? formAutoPlace.address_en : null,
      };
      return {
        first_name: this.searchAllRecordsFormState.first_name,
        surname: this.searchAllRecordsFormState.surname,
        ...autoPlace,
        residence_location: this.searchAllRecordsFormState.residence_location
          ? this.searchAllRecordsFormState.residence_location.id
          : null,
        birth_location_id: this.searchAllRecordsFormState.birth_location
          ? this.searchAllRecordsFormState.birth_location.id
          : null,
        death_location_id: this.searchAllRecordsFormState.death_location
          ? this.searchAllRecordsFormState.death_location.id
          : null,
        birth_year: this.searchAllRecordsFormState.birth_year,
        death_year: this.searchAllRecordsFormState.death_year,
        source_types: this.searchAllRecordsFormState.source_types,
        gender: this.searchAllRecordsFormState.gender,
        country_id: this.searchAllRecordsFormState.source_location
          ? this.searchAllRecordsFormState.source_location.id
          : null,
        category_id: this.searchAllRecordsFormState.category_id,
        source_id: this.searchAllRecordsFormState.source_id,
        cemetery_id: this.searchAllRecordsFormState.cemetery_id,
        clan_name: this.searchAllRecordsFormState.clan_name,
        record_year: this.searchAllRecordsFormState.record_year,
        has_images: this.searchAllRecordsFormState.has_images,
        with_fuzzy_first_name: this.searchAllRecordsFormState.with_fuzzy_first_name,
        with_fuzzy_surname: this.searchAllRecordsFormState.with_fuzzy_surname,
        ancestral_place_id: this.searchAllRecordsFormState.ancestral_place
          ? this.searchAllRecordsFormState.ancestral_place.id
          : null,
        facts: this.getFactsFilters(this.searchAllRecordsFormState.facts),
        relatives: this.getRelativesFilters(this.searchAllRecordsFormState.relatives),
        collection_id: this.searchAllRecordsFormState.collection ? this.searchAllRecordsFormState.collection.id : null,
      };
    },
    filtersSources() {
      return {
        q: this.searchAllSourcesFormState.q,
        auto_place_id: this.searchAllSourcesFormState.auto_place ? this.searchAllSourcesFormState.auto_place.id : null,
        country: this.searchAllSourcesFormState.country,
        clan_name: this.searchAllSourcesFormState.clan_name,
        ...this.getAncestralPlacesFilters(this.searchAllSourcesFormState),
        ancestral_place_id: this.searchAllSourcesFormState.ancestral_place
          ? this.searchAllSourcesFormState.ancestral_place.id
          : null,
        publication_year: this.searchAllSourcesFormState.publication_year,
        category_id: this.searchAllSourcesFormState.category_id,
        source_type: this.searchAllSourcesFormState.source_type,
        record_format: this.searchAllSourcesFormState.record_format,
        has_ocr: this.searchAllSourcesFormState.has_ocr,
        has_images: this.searchAllSourcesFormState.has_images,
        collection_id: this.searchAllSourcesFormState.collection ? this.searchAllSourcesFormState.collection.id : null,
      };
    },
    mainTitleMainPart() {
      const mapping = {
        [this.tabIdIndexedRecords]: this.mainTitleIndexedRecords,
        [this.tabIdFullText]: this.mainTitleFullText,
        [this.tabIdSources]: this.mainTitleSources,
      };
      return this.activeTab ? mapping[this.activeTab] : '';
    },
    mainTitle() {
      if (this.isDesktop) {
        return '';
      }
      const initText = `Search ${this.categoryDisplayName}`;
      const mainPart = this.mainTitleMainPart;
      return this.searchRan ? `Search Results ${mainPart}` : initText;
    },
    pageTitle() {
      const {source_types, source_type} = this.activeForm;
      const typeId = source_types || source_type;
      const sourceType = typeId ? this.sourceTypes.find(c => c.value == typeId || c.object_id == typeId) : null;
      const title = 'Search All Records';
      const category = sourceType ? sourceType.name : this.selectedCategory ? this.selectedCategory.explicit_name : '';
      return category ? `Search Overseas Chinese ${category}` : title;
    },
    selectedCategory() {
      const options = this.searchAllRecordsOptionsState;
      if (this.filters.category_id && options && options.categories) {
        return options.categories.find(item => item.id === this.filters.category_id);
      }
    },
    categoryDisplayName() {
      const category = this.selectedCategory;
      return category ? category.explicit_name : 'All Records';
    },
    mainTitleIndexedRecords() {
      const collectionName = this.categoryDisplayName;
      const {surname, first_name, source_types, auto_place} = this.searchAllRecordsFormState;
      const sourceTypes = this.searchAllRecordsOptionsState && this.searchAllRecordsOptionsState.source_types;
      return this.getTitleMainPart(collectionName, surname, first_name, source_types, sourceTypes, auto_place);
    },
    mainTitleFullText() {
      const collectionName = this.categoryDisplayName;
      const {last_name, q, source_type} = this.sourcesTextSearchFormState;
      const sourceTypes = this.searchAllRecordsOptionsState && this.searchAllRecordsOptionsState.source_types;
      return this.getTitleMainPart(collectionName, last_name, q, source_type, sourceTypes, null);
    },
    mainTitleSources() {
      const collectionName = this.categoryDisplayName;
      const {source_type, auto_place} = this.searchAllSourcesFormState;
      const sourceTypes = this.searchMeta.facets && this.searchMeta.facets.source_type;
      return this.getTitleMainPart(collectionName, '', '', source_type, sourceTypes, auto_place);
    },
    firstName() {
      const mapping = {
        [TAB_ID_INDEXED_RECORDS]: this.searchAllRecordsFormState.first_name,
        [TAB_ID_FULL_TEXT]: this.sourcesTextSearchFormState.q,
        [TAB_ID_SOURCES]: '',
      };
      return this.activeTab ? mapping[this.activeTab] : '';
    },
    lastName() {
      const mapping = {
        [TAB_ID_INDEXED_RECORDS]: this.searchAllRecordsFormState.surname,
        [TAB_ID_FULL_TEXT]: this.sourcesTextSearchFormState.last_name,
        [TAB_ID_SOURCES]: '',
      };
      return this.activeTab ? mapping[this.activeTab] : '';
    },
    activeForm() {
      const mapping = {
        [TAB_ID_INDEXED_RECORDS]: this.searchAllRecordsFormState,
        [TAB_ID_FULL_TEXT]: this.sourcesTextSearchFormState,
        [TAB_ID_SOURCES]: this.searchAllSourcesFormState,
      };
      return this.activeTab ? mapping[this.activeTab] : {};
    },
    showFilters() {
      return true;
    },
  },
  methods: {
    ...searchPageMethods,
    runSearch(filters, trackSearchType) {
      this.searchError = false;
      this.searchErrorText = '';
      const mapping = {
        [this.tabIdIndexedRecords]: this.searchRecordsIndexed,
        [this.tabIdFullText]: this.searchRecordsFullText,
        [this.tabIdSources]: this.searchSources,
      };
      this.clearTabsExceptActive();
      return mapping[this.activeTab](filters)
        .then(() => {
          const params = {...filters, tab: this.activeTab};
          AnalyticsAmplitudeHandler.trackAllRecordsSearchSubmit(params, this.searchMeta.total_count, trackSearchType);
        })
        .catch(error => {
          this.searchError = true;
          this.searchErrorText = error && error.response && error.response.data ? error.response.data.error : '';
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchRecordsIndexed(filters) {
      return this.$store.dispatch('searchAllRecordsAction', filters).then(response => {
        if (response.meta.is_relaxed_search && response.meta.total_count) {
          this.$router.replace({
            query: {...this.$route.query, with_fuzzy_first_name: 'true', with_fuzzy_surname: 'true'},
          });
          this.mutateIndexedForm({with_fuzzy_first_name: true, with_fuzzy_surname: true});
        }
      });
    },
    searchRecordsFullText(filters) {
      return this.$store.dispatch('sourcesTextSearchAction', filters);
    },
    searchSources(filters) {
      return this.$store.dispatch('searchSourcesAction', filters);
    },
    parseFiltersFromQuery() {
      this.activeTab = this.$route.query.tab;
      const mapping = {
        [this.tabIdIndexedRecords]: this.parseFiltersFromQueryIndexedRecords,
        [this.tabIdFullText]: this.parseFiltersFromQueryFullText,
        [this.tabIdSources]: this.parseFiltersFromQuerySources,
      };
      return this.activeTab ? mapping[this.activeTab]() : null;
    },
    parseFiltersFromQueryIndexedRecords() {
      const parsedFilters = this.getParsedQueryFiltersIndexed();
      this.$store.commit('mutateSearchAllRecordsFormState', parsedFilters);
      if (this.$route.query.ancestral_place_id && this.$route.query.ancestral_place_id !== 'none') {
        this.network.place
          .getPlacesDetail({id: this.$route.query.ancestral_place_id, fields: 'full_address_en,address_en,id,parents'})
          .then(res => {
            this.$store.commit('mutateSearchAllRecordsFormState', {ancestral_place: res});
          });
      }
      if (this.$route.query.auto_place_id) {
        this.network.place
          .getPlacesDetail({id: this.$route.query.auto_place_id, fields: 'full_address_en,address_en,id,parents'})
          .then(res => {
            this.$store.commit('mutateSearchAllRecordsFormState', {auto_place: res});
          });
      }
      if (this.$route.query.birth_location_id) {
        this.network.place
          .getPlacesDetail({id: this.$route.query.birth_location_id, fields: 'full_address_en,address_en,id,parents'})
          .then(res => {
            this.$store.commit('mutateSearchAllRecordsFormState', {birth_location: res});
          });
      }
      if (this.$route.query.death_location_id) {
        this.network.place
          .getPlacesDetail({id: this.$route.query.death_location_id, fields: 'full_address_en,address_en,id,parents'})
          .then(res => {
            this.$store.commit('mutateSearchAllRecordsFormState', {death_location: res});
          });
      }
      if (this.$route.query.residence_location) {
        this.network.place
          .getPlacesDetail({id: this.$route.query.residence_location, fields: 'full_address_en,address_en,id,parents'})
          .then(res => {
            this.$store.commit('mutateSearchAllRecordsFormState', {residence_location: res});
          });
      }
      if (this.$route.query.country_id) {
        this.network.place
          .getPlacesDetail({id: this.$route.query.country_id, fields: 'full_address_en,address_en,id,parents'})
          .then(res => {
            this.$store.commit('mutateSearchAllRecordsFormState', {source_location: res});
          });
      }
      this.fetchFactsRelatedObjects(parsedFilters.facts || []);
      this.fetchCollectionObject(this.mutateIndexedForm);
    },
    mutateFullTextForm(data) {
      return this.$store.commit('mutateSourcesTextSearchFormState', data);
    },
    mutateSourcesForm(data) {
      return this.$store.commit('mutateSearchAllSourcesFormState', data);
    },
    mutateIndexedForm(data) {
      return this.$store.commit('mutateSearchAllRecordsFormState', data);
    },
    clearSearchResults() {
      this.$store.commit('setSearchAllRecordsListState', []);
      this.$store.commit('setSearchAllRecordsMetaState', {});
      this.$store.commit('setSourcesTextSearchListState', []);
      this.$store.commit('setSourcesTextSearchMetaState', {});
      this.$store.commit('setSearchAllSourcesListState', []);
      this.$store.commit('setSearchAllSourcesMetaState', {});
    },
    handleEmptyQuery(toQuery) {
      if (!this.filtersInQuery(this.activeTab)) {
        this.searchRan = false;
        this.showAdvancedSearch = Boolean(this.$route.query.advanced_search);
        this.clearSearchResults();
        this.fetchAndSetInitialSearchPageState(this.$route.query.category_id);
        const data = {category_id: this.$route.query.category_id};
        this.$store.commit('clearSearchAllRecordsFormState', data);
        this.$store.commit('clearSourcesTextSearchFormState', data);
        this.$store.commit('clearSearchAllSourcesFormState', data);
        return true;
      }
      this.searchRan = true;
    },
    clearTabsExceptActive() {
      const tabsClear = {
        [this.tabIdIndexedRecords]: () => this.$store.commit('clearSearchAllRecordsFormState'),
        [this.tabIdFullText]: () => this.$store.commit('clearSourcesTextSearchFormState'),
        [this.tabIdSources]: () => this.$store.commit('clearSearchAllSourcesFormState'),
      };
      for (let [tab, clearMethod] of Object.entries(tabsClear)) {
        if (tab !== this.activeTab) {
          clearMethod();
        }
      }
    },
    onFilterSelect({typeName, value, deselectFields}) {
      const mapping = {
        [this.tabIdIndexedRecords]: this.onFilterSelectIndexed,
        [this.tabIdFullText]: this.onFilterSelectFullText,
        [this.tabIdSources]: this.onFilterSelectSources,
      };
      const method = mapping[this.activeTab];
      return method ? method({typeName, value, deselectFields}) : null;
    },
    onFilterSelectFullText({typeName, value, deselectFields}) {
      this.$store.commit(
        'mutateSourcesTextSearchFormState',
        this.getPayloadForFiltersCommit(typeName, value, deselectFields)
      );
      this.onSubmit({}, 'results filter');
    },
    onFilterSelectSources({typeName, value, deselectFields}) {
      this.$store.commit(
        'mutateSearchAllSourcesFormState',
        this.getPayloadForFiltersCommit(typeName, value, deselectFields)
      );
      this.onSubmit({}, 'results filter');
    },
    isRouteSwitchToAdvancedForm(obj1, obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);
      const uniqueInObj1 = keys1.filter(key => !keys2.includes(key) || obj2[key] === undefined);
      const uniqueInObj2 = keys2.filter(key => !keys1.includes(key) || obj1[key] === undefined);
      const diffKeys = [...uniqueInObj1, ...uniqueInObj2];
      return diffKeys.length === 1 && diffKeys[0] === 'advanced_search';
    },
  },
  components: {
    BlockBrowseZupus,
    SearchResultsHelper,
    FiltersContainer,
    FiltersContainerMobile,
    FormTabsBlockContainer,
    SearchResults,
    StickyEditButtons,
    EditSearchModal,
    ActiveFilterTag,
    SearchError,
    YourSearchForm,
  },
};
