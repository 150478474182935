<template>
  <div
    class="images-gallery-overlay"
    v-bind:class="{'info-bar-shown': infoBarShown, 'info-bar-left-side': isInfoBarLeftSide}"
    v-touch:swipe.left="onSwipeLeft"
    v-touch:swipe.right="onSwipeRight"
    @touchstart="touchstart"
    @touchmove="touchmove"
  >
    <close-icon v-if="showClose" :size="24" @click="clickClose" class="dark"></close-icon>
    <div class="content-container">
      <slot name="absolute-actions"></slot>
      <div v-if="!infoBarShown && $slots['info-bar']" class="hide-button show-button" @click="toggleInfoBar">
        &lsaquo; Details
      </div>
      <transition :name="infoBarTransitionName">
        <div v-if="infoBarShown" class="info-bar">
          <div v-if="infoBarShown" class="hide-button" @click="toggleInfoBar">Hide &rsaquo;</div>
          <slot name="info-bar"></slot>
        </div>
      </transition>
      <div class="image-port" :class="imagePortClass">
        <preview-unavailable-living-person v-if="isBlurred"></preview-unavailable-living-person>
        <div class="image-container" :class="imageContainerClasses">
          <transition :name="imageSlideTransitionName">
            <div v-show="showImage" class="image">
              <video v-if="isVideo" class="video-player" controls>
                <source :src="src" />
                Your browser does not support the video tag.
              </video>
              <audio v-else-if="isAudio" class="audio-player" controls>
                <source :src="src" />
                Your browser does not support the audio tag.
              </audio>
              <pan-zoom
                v-else
                ref="panzoom"
                :options="panZoomOptions"
                :class="{panable: panable}"
                @zoom="onPanZoomZoom"
              >
                <div class="pan-zoom-container" id="pan-zoom-container" ref="transformable">
                  <area-selection-overlay
                    v-if="isModeAreaSelection && isImageLoaded"
                    ref="area-selection-overlay"
                    :image-width="imageWidth"
                    :image-height="imageHeight"
                    :scale="getPanZoomScale()"
                    :render-scale="imageRenderScale"
                    :url="src"
                    @area-selected="showConfirmationOcr"
                    @area-selection-started="showConfirmationOcr(false)"
                  >
                  </area-selection-overlay>
                  <img
                    @mousedown.prevent
                    ref="image"
                    :src="src"
                    :key="transitionKey || src"
                    :style="imageStyle"
                    @load="onImageLoad"
                  />
                </div>
              </pan-zoom>
            </div>
          </transition>
          <mcr-loading-indicator v-show="!src" :loading="true" />
          <transition :name="imageSlideTransitionName">
            <div v-show="showImage" class="filename">{{ filename }}</div>
          </transition>
        </div>
        <div
          v-if="!isModeAreaSelection && hasPrevious"
          class="gallery-nav prev"
          :class="{active: hasPrevious}"
          @click="clickPrevious"
        ></div>
        <div
          v-if="!isModeAreaSelection && hasNext"
          class="gallery-nav next"
          :class="{active: hasNext}"
          @click="clickNext"
        ></div>
        <slot name="image-port-actions"></slot>
      </div>
    </div>
    <slot name="modals"></slot>
  </div>
</template>

<script>
import AreaSelectionOverlay from '@common/elements/layouts/area-selection/AreaSelectionOverlay';
import PreviewUnavailableLivingPerson from '@common/elements/layouts/book-viewer/PreviewUnavailableLivingPerson';
import CloseIcon from 'vue-material-design-icons/Close';

import {isVideoFile, isAudioFile, isImageFile} from '@/components/common/filePreviewHelper';

export default {
  props: {
    src: String,
    transitionKey: {type: String, required: false}, //if src is not unique, pass this value
    hasNext: Boolean,
    hasPrevious: Boolean,
    showClose: {type: Boolean, default: true},
    imageContainerClasses: {type: [String, Object, Array], required: false},
    allowTouch: {type: Boolean, required: false, default: true},
    isInfoBarLeftSide: {type: Boolean, default: false},
    initInfoBarHidden: {type: Boolean, default: false},
    rotateImage: {type: Number, default: 0},
    filename: String,
    isModeAreaSelection: {type: Boolean, default: false},
    isBlurred: {type: Boolean, default: false},
  },
  data() {
    return {
      showImage: true,
      isImageLoaded: false,
      infoBarShown: !this.initInfoBarHidden,
      imageSlideTransitionName: '',
      infoBarTransitionName: this.isInfoBarLeftSide ? 'info-bar-slide-left' : 'info-bar-slide-right',
      isZoomed: false,
    };
  },
  created() {
    window.addEventListener('keyup', this.keyupHandler);
  },
  destroyed() {
    window.removeEventListener('keyup', this.keyupHandler);
  },
  computed: {
    imagePortClass() {
      let classes = [];
      if (!this.infoBarShown) {
        classes.push('is-full-screen');
      }
      return classes;
    },
    imageStyle() {
      return {transform: `rotate(${this.rotateImage}deg)`};
    },
    isVideo() {
      return isVideoFile(this.filename);
    },
    isAudio() {
      return isAudioFile(this.filename);
    },
    isImage() {
      return this.filename ? isImageFile(this.filename) : true;
    },
    imageWidth() {
      return this.$refs.image ? this.$refs.image.width : 0;
    },
    imageHeight() {
      return this.$refs.image ? this.$refs.image.height : 0;
    },
    imageRenderScale() {
      const widthScale = this.$refs.image.naturalWidth / this.imageWidth;
      const heightScale = this.$refs.image.naturalHeight / this.imageHeight;
      return Math.max(widthScale, heightScale);
    },
    panZoomOptions() {
      return {
        bounds: false,
        maxZoom: 10,
        filterKey: () => {
          return true;
        },
        onTouch: this.panZoomTouch,
        beforeMouseDown: this.panZoomBeforeMouseDown,
        beforeWheel: this.panZoombBeforeWheel,
      };
    },
    panable() {
      return !this.isModeAreaSelection && this.isZoomed && this.isImage;
    },
    zoomable() {
      return !this.isModeAreaSelection && this.isImage;
    },
  },
  methods: {
    touchstart(event) {
      if (event.target.tagName.toLowerCase() === 'img') {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    touchmove(event) {
      if (event.target.tagName.toLowerCase() === 'img' && event.touches.length === 1 && !this.isZoomed) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    onSwipeRight() {
      if (!this.allowTouch || this.isModeAreaSelection || this.isZoomed) {
        return;
      }
      this.clickPrevious();
    },
    onSwipeLeft() {
      if (!this.allowTouch || this.isModeAreaSelection || this.isZoomed) {
        return;
      }
      this.clickNext();
    },
    clickClose() {
      this.$emit('click-close');
    },
    clickNext() {
      if (this.hasNext) {
        this.showImage = false;
        this.isImageLoaded = false;
        this.resetPanZoom();
        this.imageSlideTransitionName = 'image-slide-right';
        this.$emit('hide-image');
        setTimeout(() => {
          this.showImage = true;
          this.$emit('click-next');
          this.imageSlideTransitionName = 'image-slide-left';
        }, 300);
      }
    },
    clickPrevious() {
      if (this.hasPrevious) {
        this.showImage = false;
        this.isImageLoaded = false;
        this.resetPanZoom();
        this.imageSlideTransitionName = 'image-slide-left';

        this.$emit('hide-image');
        setTimeout(() => {
          this.showImage = true;
          this.$emit('click-previous');
          this.imageSlideTransitionName = 'image-slide-right';
        }, 300);
      }
    },
    resetPanZoom() {
      if (this.$refs.panzoom && this.$refs.panzoom.$panZoomInstance) {
        this.$refs.panzoom.$panZoomInstance.pause();
        this.$refs.panzoom.$panZoomInstance.moveTo(0, 0);
        this.$refs.panzoom.$panZoomInstance.zoomAbs(0, 0, 1);
        this.$refs.panzoom.$panZoomInstance.resume();
      }

      this.isZoomed = false;
    },
    onPanZoomZoom(instance) {
      this.isZoomed = true;
      if (instance.getTransform().scale < 1) {
        this.resetPanZoom();
        this.isZoomed = false;
      }
    },
    panZoomTouch(event) {
      if (this.isZoomed) {
        event.preventDefault();
        event.stopPropagation();
      }
      return false;
    },
    panZoomBeforeMouseDown() {
      if (!this.panable) {
        return true;
      }
    },
    panZoombBeforeWheel() {
      if (!this.zoomable) {
        return true;
      }
    },
    getPanZoomScale() {
      return this.$refs.panzoom.$panZoomInstance.getTransform().scale;
    },
    keyupHandler(event) {
      if (['INPUT', 'TEXTAREA'].includes(event.target.tagName)) {
        return;
      }
      if (event.keyCode == 39 && !this.isModeAreaSelection) {
        return this.clickNext();
      }
      if (event.keyCode == 37 && !this.isModeAreaSelection) {
        return this.clickPrevious();
      }
      if (event.keyCode == 27) {
        if (this.$refs['area-selection-overlay']) {
          this.$refs['area-selection-overlay'].closeModal();
        } else {
          this.$emit('click-close');
        }
      }
    },
    toggleInfoBar() {
      this.infoBarShown = !this.infoBarShown;
    },
    onImageLoad() {
      this.isImageLoaded = true;
      this.$emit('image-loaded');
    },
    showConfirmationOcr(value) {
      this.$emit('area-selected', value);
    },
    confirmOcrSelection() {
      if (this.$refs['area-selection-overlay'].areaSelectionEnded) {
        return this.$refs['area-selection-overlay'].showModal();
      }
      this.$toasted.error('Select image area to OCR.');
    },
  },
  components: {PreviewUnavailableLivingPerson, CloseIcon, AreaSelectionOverlay},
};
</script>

<style lang="scss" scoped>
$info-bar-width: 400px;
$info-bar-z-index: 1;
$hide-button-z-index: 2; // calc(#{$info-bar-z-index} + 1); - calc z-index doesn't work in IE
$close-button-z-index: 2; //calc(#{$info-bar-z-index} + 1); - calc z-index doesn't work in IE

.images-gallery-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.96);
  top: 0;
  left: 0;
  z-index: 12347; // calc(#{$modal-z-index} + 1); - calc z-index doesn't work in IE
  color: $mcr-light-grey;

  .hide-button {
    padding: 35px 0 0 35px;
    cursor: pointer;
    position: absolute;
    top: 1px;
    left: 0px;
    color: $supplemental-text-color;
    z-index: $hide-button-z-index;
    opacity: 0.5;
    transition: all 0.3s ease;

    &.show-button {
      right: 65px;
      left: auto;
      white-space: nowrap;
    }

    &:hover {
      opacity: 1;
    }
  }

  .preview-unavailable {
    z-index: 2;
  }

  .content-container {
    height: 100%;

    .info-bar {
      padding-top: 25px;
      position: absolute;
      z-index: $info-bar-z-index;
      right: 0;
      top: 0;
      width: $info-bar-width;
      height: 100%;
      box-sizing: border-box;
      background-color: #1c1c1c;
      overflow: auto;
    }

    .image-port {
      position: absolute;
      top: 0;
      right: $info-bar-width;
      width: calc(100% - #{$info-bar-width});
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: all 0.4s ease;

      .mcr-loading-indicator {
        opacity: 0.2;
        margin: auto;
        position: absolute;
      }

      .video-player {
        max-width: 100%;
        max-height: 100%;
        z-index: 10;
      }

      .audio-player {
        width: 100%;
        z-index: 10;
      }

      .image-container {
        height: calc(100% - 216px);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 8px;
        box-sizing: border-box;

        .pan-zoom-container {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
        }

        .vue-pan-zoom-item {
          height: 100%;
          width: 100%;
          &.panable {
            cursor: grab;
          }

          &.panable:active {
            cursor: grabbing;
          }

          &::v-deep .vue-pan-zoom-scene {
            height: 100%;
            width: 100%;
          }
        }

        .image {
          height: calc(100% - 32px);
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-height: 100%;
          }
        }

        &:not(.is-image) {
          box-sizing: border-box;
          padding: 16%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          @media only screen and (max-width: $breakpoint-mobile) {
            padding: 12%;
          }
        }

        .filename {
          margin-top: 10px;
          text-align: center;
        }
      }

      .gallery-nav {
        position: absolute;
        color: #fff;
        background: black;
        font-size: 56px;
        line-height: 56px;
        height: 56px;
        width: 56px;
        cursor: pointer;
        opacity: 0.7;
        transition: all 0.5s ease;
        top: calc(50% - 32px);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;

        &.prev {
          left: 12px;

          &:after {
            margin-top: -6px;
            margin-right: 2px;
            content: '\2039';
          }
        }

        &.next {
          right: 12px;

          &:after {
            margin-top: -6px;
            margin-left: 2px;
            content: '\203A';
          }
        }

        &:hover {
          opacity: 0.9;
        }

        &:not(.active) {
          cursor: default;
          opacity: 0;
        }
      }
    }
  }

  .close-icon {
    position: absolute;
    z-index: $close-button-z-index;
    right: 16px;
    top: 20px;
    cursor: pointer;
  }
}

.images-gallery-overlay.info-bar-left-side {
  .info-bar,
  .hide-button {
    left: 0;
  }

  .image-port {
    left: $info-bar-width;
  }
}

.images-gallery-overlay:not(.info-bar-shown) .image-port {
  width: 100%;
  right: 0;
  left: 0;
}

@media only screen and (max-width: $breakpoint-desktop) {
  .images-gallery-overlay {
    .hide-button {
      display: none;
    }

    .content-container {
      .image-port {
        width: 100%;
        left: 0;

        &.is-full-screen {
          height: 100%;
          margin-top: 0;
        }

        .image-container {
          height: calc(100% - 30px);
        }
      }

      .info-bar {
        padding-top: 0;
        background-color: rgba(black, 0.9);
        top: 0;
        width: 100%;
        height: auto;
        max-height: 100%;
        overflow: auto;
      }
    }
  }
}

.image-slide-right-enter-active,
.image-slide-left-enter-active,
.image-slide-right-leave-active,
.image-slide-left-leave-active {
  transition: all 0.2s ease;
}

.image-slide-right-enter,
.image-slide-right-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}

.image-slide-left-enter,
.image-slide-left-leave-to {
  transform: translateX(200px);
  opacity: 0;
}
</style>
