<template>
  <div class="fact-form sub-form">
    <div class="heading">
      <div class="text-md text-lg-mobile bold">{{ label }}</div>
      <close-icon class="close-icon close-form-icon" @click="$emit('remove')"></close-icon>
    </div>

    <hr />

    <div class="inline" v-if="displayValueInput || (displayYearInput && !displayYearWithPlace)">
      <div class="input-wrapper" v-if="displayValueInput">
        <div class="input-label">{{ valueLabel }}</div>
        <input class="input full-width" :value="fact.value" @input="onValueChange" @keyup.enter="onInputEnter" />
      </div>

      <div class="input-wrapper" v-if="displayYearInput && !displayYearWithPlace">
        <div class="input-label">Year</div>
        <input
          class="input year-input"
          :value="fact.year"
          placeholder="YYYY"
          maxlength="4"
          size="4"
          @input="onYearChange"
          @keyup.enter="onInputEnter"
        />
      </div>
    </div>

    <div class="inline" v-if="displayPlaceInput || (displayYearInput && displayYearWithPlace)">
      <div class="input-wrapper" v-if="displayPlaceInput">
        <place-input
          label="Relevant Place"
          :id="`fact-${fact.formId}-place`"
          placeholder="All Places"
          multiselect-classes="bordered"
          label-classes="input-label"
          :value="fact.place || {}"
          @select="onPlaceChange"
        ></place-input>
      </div>

      <div class="input-wrapper" v-if="displayYearInput && displayYearWithPlace">
        <div class="input-label">Year</div>
        <input
          class="input year-input"
          :value="fact.year"
          placeholder="YYYY"
          maxlength="4"
          size="4"
          @input="onYearChange"
          @keyup.enter="onInputEnter"
        />
      </div>
    </div>

    <div class="inline" v-if="displayCemeteryInput">
      <div class="input-wrapper">
        <div class="input-label">Cemetery</div>
        <source-input
          placeholder="All Cemeteries"
          multiselect-classes="bordered"
          :value="fact.cemetery || {}"
          source-types="cemetery"
          @select="onCemeteryChange"
        ></source-input>
      </div>
    </div>
  </div>
</template>

<script>
import PlaceInput from '@common/elements/inputs/PlaceInput';
import SourceInput from '@common/elements/inputs/SourceInput';
import CloseIcon from 'vue-material-design-icons/CloseCircle';
import {mapGetters} from 'vuex';

export default {
  props: {
    label: String,
    fieldsList: Array,
    fact: Object,
  },
  computed: {
    ...mapGetters(['sourcesAutocompleteLoadingState', 'sourcesAutocompleteState']),
    displayValueInput() {
      return this.fieldsList.includes('value');
    },
    displayPlaceInput() {
      return this.fieldsList.includes('place');
    },
    displayYearInput() {
      return this.fieldsList.includes('start_date') || this.fieldsList.includes('end_date');
    },
    displayCemeteryInput() {
      return this.fieldsList.includes('cemetery');
    },
    displayYearWithPlace() {
      return this.displayPlaceInput;
    },
    valueLabel() {
      const mapping = {
        crime_committed: 'Crime Type',
      };
      return mapping[this.fact.fact_type] || 'Value';
    },
  },
  methods: {
    onValueChange(event) {
      this.$emit('change-value', event.target.value.trim());
    },
    onYearChange(event) {
      this.$emit('change-year', event.target.value.trim());
    },
    onPlaceChange(place) {
      this.$emit('change-place', place);
    },
    onCemeteryChange(cemetery) {
      this.$emit('change-cemetery', cemetery);
    },
    onInputEnter() {
      this.$emit('submit');
    },
  },
  components: {SourceInput, PlaceInput, CloseIcon},
  name: 'FactForm',
};
</script>

<style lang="scss" scoped>
.fact-form {
  .inline {
    .input-wrapper:first-child {
      flex-grow: 1;
    }
  }
}
</style>
