<template>
  <multiselect
    :value="value"
    ref="source-input"
    :placeholder="placeholder"
    :searchable="true"
    :internal-search="false"
    :preserveSearch="false"
    :clearOnSelect="true"
    :show-no-options="true"
    :show-no-results="searchRan"
    :hide-selected="false"
    :options="sourcesAutocompleteState"
    track-by="id"
    label="name"
    openDirection="bottom"
    selectLabel=""
    deselectLabel="Selected"
    :loading="sourcesAutocompleteLoadingState"
    class="bordered"
    @search-change="onSearchChange"
    @select="onSelect"
    @close="clearOptions"
  >
    <template v-slot:caret>
      <div
        class="caret-icon close-icon"
        v-if="value.id && !isMultiselectOpened()"
        @mousedown.prevent.stop="clearSource()"
      >
        <close-icon></close-icon>
      </div>
    </template>
    <span slot="noResult" class="input-helper">Not found</span>
    <span slot="noOptions" class="input-helper">Start typing to search...</span>
    <span slot="singleLabel" slot-scope="props">
      <template>{{ props.option.name || placeholder || 'All Sources' }}</template>
    </span>
    <div slot="afterList" class="loading-after-list" v-if="sourcesAutocompleteLoadingState">
      <span class="input-helper">Searching...</span>
    </div>
  </multiselect>
</template>

<script>
import debounce from 'lodash/debounce';
import CloseIcon from 'vue-material-design-icons/Close';
import Multiselect from 'vue-multiselect';
import {mapGetters} from 'vuex';

export default {
  props: {
    value: Object,
    placeholder: {type: String, default: ''},
    sourceTypes: String, // comma separated list of source types
  },
  data() {
    return {
      searchRan: false,
    };
  },
  computed: {
    ...mapGetters(['sourcesAutocompleteLoadingState', 'sourcesAutocompleteState']),
  },
  methods: {
    onSearchChange(query) {
      this.$store.commit('setSourcesAutocompleteState', []);
      if (!query) {
        return;
      }
      this.searchSources(query);
    },
    searchSources: debounce(function (query) {
      this.$store.dispatch('fetchSourcesAutocompleteAction', {source_types: this.sourceTypes, q: query});
    }, 200),
    onSelect(value) {
      this.$emit('select', value);
    },
    clearSource() {
      this.onSelect({});
    },
    clearOptions() {
      this.$store.commit('setSourcesAutocompleteState', []);
    },
    isMultiselectOpened() {
      return this.$refs['source-input'] && this.$refs['source-input'].isOpen;
    },
  },
  components: {Multiselect, CloseIcon},
  name: 'SourceInput',
};
</script>

<style lang="scss" scoped></style>
